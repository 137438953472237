<template>
  <div class="main-content">
    <avue-form ref="form" v-model="form" :option="formOption">
      <template slot="modules">
        <div class="flex">
          <!-- <el-button type="primary" @click="handImport">导入</el-button> -->
          <el-upload
            class="upload-demo"
            action="/api/web/student/plan/importModule"
            :show-file-list="false"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          >
            <el-button type="primary">导入</el-button>
          </el-upload>
          <el-button
            class="marginLeft20"
            type="primary"
            @click="handleExport"
            :loading="showLoadingExcel"
            >下载导入模版</el-button
          >
        </div>
        <div>
          <Table :tableData="form.modules" @handleDelete="handleDelete" />
        </div>
      </template>
      <template slot="menuForm">
        <el-button @click="handleClose">取消</el-button>
        <el-button
          type="primary"
          :loading="showLoadingForm"
          @click="submit(form)"
          >确定</el-button
        >
      </template>
    </avue-form>
  </div>
</template>

<script>
import {
  studentPlanPlanView,
  studentLearnAddPlan,
  studentLearnExport,
  studentPlanImportModele,
} from "@/api/user/level";
import dayjs from "dayjs";
import qs from "qs";
import Table from "./components/table.vue";
import { exportExcel } from "@/components/exportExcel";
// import { MChannel, OChannel, EChannel } from "@/enums/beCurrent/EBeCurrent";
export default {
  name: "index",
  components: {
    Table,
  },
  data() {
    return {
      newPage: this.$route.query.newPage,
      showLoadingExcel: false,
      showLoading: false,
      showLoadingForm: false,
      id: this.$route.query.id,
      form: {
        id: "",
        modules: [],
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "120",
        row: true,
        column: [
          {
            label: "听说目标",
            prop: "hearTarget",
            type: "textarea",
            maxlength: 300,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "输入听说目标",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "阅读目标",
            prop: "readTarget",
            type: "textarea",
            maxlength: 300,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "输入阅读目标",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "书写目标",
            prop: "writeTarget",
            type: "textarea",
            maxlength: 300,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "输入书写目标",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "技能目标",
            prop: "skillTarget",
            type: "textarea",
            maxlength: 300,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "输入技能介绍",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "单元介绍",
            prop: "modules",
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "",
            rules: [
              {
                required: true,
                message: "请导入",
                trigger: "blur",
              },
              {
                validator(_, value) {
                  if (value) {
                    if (!value.length) {
                      return Promise.reject(new Error("请导入单元介绍"));
                    }
                  }
                  return Promise.resolve(true);
                },
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      studentPlanPlanView(this.id).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.form = {
            ...res.data,
            modules: res.data.list,
          };
          delete this.form.list;
        }
      });
    },

    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          studentLearnAddPlan({
            ...form,
            levelId: this.id,
          })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                setTimeout(() => {
                  this.resetForm();
                }, 500);
                this.$router.go(-1);
                this.showLoadingForm = false;
              } else {
                // this.$message.error(res.msg);
                this.showLoadingForm = false;
                done();
              }
            })
            .catch(() => (this.showLoadingForm = false));
          // this.form.type = 1;
          // done()
        }
      });
    },
    //导入
    handImport() {},
    //导出excel
    async handleExport() {
      try {
        if (this.showLoadingExcel) return;
        this.showLoadingExcel = true;
        let params = {};
        await exportExcel(
          "/web/student/learn/export",
          params,
          this.$store.state.user.Token
        );
        console.log("111111");
        this.showLoadingExcel = false;
      } catch (e) {
        this.showLoadingExcel = false;
      }
    },
    //删除方法
    handleDelete(row) {
      // this.$confirm("确定删除吗?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   this.$message.success("删除成功!");
      // });
      this.form.modules = this.form.modules.filter(
        (item, index) => index != row.$index
      );
    },
    //返回
    handleClose() {
      // this.$router.go(-1);
      this.$router.push({
        path: "/user/level",
        query: {
          newPage: this.newPage,
        },
      });
    },
    // 导入成功
    handleSuccess(res, file) {
      // // console.log(1111111,res, file);
      this.uploadShow = false;
      if (res.code === 200) {
        this.problemState = res.data.state;
        setTimeout(() => {
          this.form.modules = res.data;
          this.dialogVisibleOne = true;
        }, 500);
      } else {
        this.$message.error(res.msg);
        this.uploadShow = false;
      }
    },
    beforeUpload(file) {
      this.loading = true;
      // 文件类型
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 20;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过20M！");
      }
      // this.loading = false
      return fileType && fileLimit;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-button--small {
  padding: 11px 15px;
}
</style>
