<template>
  <el-table :data="tableData" style="width: 100%">
    <el-table-column prop="moduleName" label="单元名称" width="180">
    </el-table-column>
    <el-table-column prop="vocabularyIntroduction" label="词汇介绍" width="180">
    </el-table-column>
    <el-table-column prop="sentenceIntroduction" label="句型介绍" width="180">
    </el-table-column>
    <el-table-column prop="skillIntroduction" label="技能介绍" width="180">
    </el-table-column>
    <el-table-column prop="bookIntroduction" label="绘本介绍" width="180">
    </el-table-column>
    <el-table-column fixed="right" label="操作" width="100">
      <template slot-scope="scope">
        <el-button @click="handleDelete(scope)" type="text" size="small"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    handleDelete(row) {
      this.$emit("handleDelete", row);
    },
  },
};
</script>

<style scoped>
/deep/.cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 12px;
  color: #000000d9 !important;
}
</style>
